@import 'basics';

.frame {
  display: flex;
  flex-direction: column;
}

.mainPage{
  margin-top:$header-height;
  padding:15px 25px;
}