@import 'basics';

.header{
  height:$header-height;
  width:100vw;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
  background: $bg-dark-light;
  position: fixed;
  top:0;
  display: flex;
  align-items: center;
}

.logo{
  margin-left:25px;
  height:30px;
}

.menu{
  margin-left:auto;
  margin-right:25px;
}

.logout{
  cursor: pointer;
  transition: color 200ms ease-in-out;

  &:hover{
    color:$primary;
  }
}