@import 'basics';

.wrapper{
  margin:10px 0 10px;
}

.select{
  width:100%;
  margin:0;
}

.error{
  color:$danger;
  font-size:12px;
  margin-top:-5px;
  display: block;
}