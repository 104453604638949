@import 'basics';

.root{
  // display: flex;
  flex:1;
  padding:15px;
}

.noTasks{
  display: flex;
  flex-direction: column;
  flex:1;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
}

.noTasksImage{
  width:500px;
  object-fit: contain;
  margin-bottom: 20px;
}

.header{
  text-align: center;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttons{
  margin-left:auto;
}

.dashboardsWrapper{
  margin:0;
}

.dashboards{
  display: flex;
  flex-direction: column;
  background:$bg-dark-1;
  border-radius: 3px;
  max-height:450px;
  overflow: auto;
  border:1px solid $border-dark;
}

.dashboardItem{
  background:transparent;
  border:0;
  padding:10px 10px;
  color:white;
  width:450px;
  height:40px;
  text-align: left;
  transition: background-color 300ms ease-in-out;

  &:hover, &.active{
    background:$bg-dark-lighter;
  }
}

.dashboardArrow{
  font-size:14px;
}

.content{
  opacity: 0.75;
  font-size:14px;
  font-weight: 200;
  margin-bottom: 40px;
}

.upload{
  margin-bottom:5px;
}

.uploadNewForecast{
  text-align: left;
}