@import 'basics';
@import 'reset';

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap');

html{
  font-family: $font-muli;
  background:$bg-color;
}

body {
  margin: 0;
  padding:0;
  font-family: $font-muli;
  line-height: 1.5;
  font-size:14px;
  font-feature-settings: 'tnum', "tnum";
  background:$bg-color;
  color:white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size:32px;
  font-weight:900;
  color:white !important;
}

h2 {
  font-size:24px;
  font-weight:900;
  color:white !important;
}

h3 {
  font-size:18px;
  font-weight:700;
  color:white !important;
}

h4 {
  font-size:14px;
  font-weight:700;
  opacity: 0.5;
  color:white !important;
}

.ant-select-selector,
.ant-picker{
  border:1px solid rgba($white, 0.15) !important;
  background:$bg-darkest !important;
  color:$white !important;
  border-radius: 4px;
  min-height:48px !important;
  width:100%;
  margin:10px 0;
}

.ant-picker-clear{
  background:$bg-darkest !important;
  color: rgba($white, 0.8)  
}

.ant-select-selection-search, 
.ant-select-selection-item,
.ant-select-selection-placeholder{
  height:45px !important;
  line-height:45px !important;
}

.ant-select-arrow,
.ant-picker-suffix{
  color: rgba($white, 0.8)
}

.ant-picker-input > input{
  color:$white;
}

.ant-tag{
  margin:3px 6px 3px 0;
}

.ant-popover-arrow{
  display: none;
}

.ant-popover-inner-content, .ant-popover-inner{
  background:transparent !important;
  padding:0;
  margin:0;
}