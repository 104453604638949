@import 'basics';

.days{
  margin:10px 0;
  display:flex;
}

.day{
  height:25px;
  line-height:25px;
  width:25px;
  margin:3px;
  font-size:12px;
  cursor: pointer;
  border-radius: 50%;
  border:1px solid rgba($white, 0.15) !important;
  background:$bg !important;
  color:$white !important;
  text-align: center;
  transition: 
    background-color 300ms ease-in-out,
    border-color 300ms ease-in-out;

  &.active{
    background:$primary !important;
    border-color:$primary-bright !important;
  }
}

.error{
  color:$danger;
  margin-top:-5px;
  font-size:12px;
  display: block;
}