@import 'basics';

.root{
  display: flex;
  flex-direction: column;
  margin:10px;
  flex:1;
  min-height:450px;
  padding:90px 0 0 0;
  position: relative;
  overflow: hidden;
}

.dashboard{
  border:1px solid $border-dark;
  background:$bg-dark-lighter;
  border-radius: 10px;
  overflow: hidden;
  flex:1;
}

.location{
  background:$bg-dark-light;
  height:53px;
  display: flex;
  align-items: center;
  padding-left:20px;

  h5{
    color:white !important;
  }
}

.timelineWrapper{
  margin-left:250px;
  z-index:2;
  position: relative;
  padding:15px 0 5px;
}

.timetable{
  display: flex;
  flex-direction: row;
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;

  &::before{
    display: block;
    width:250px;
    height:90px;
    content: '';
  }
}

.day{
  flex:1;
  text-align:center;

  &::before{
    content:' ';
    display: block;
    background:$border-dark;
    top:90px;
    bottom:0;
    width:1px;
    position: absolute;
  }
}

.header{
  height:90px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  font-size: 23px;
  font-weight: bold;

  h4{
    color:hsla(0,0%,100%,.5) !important;
    font-size:12px;
    font-weight:400;
  }
}

.dayTimes{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding:10px;
  font-weight: normal;
  font-size:9px;
  color:hsla(0,0%,100%,.5) !important;

  span{
    display: block;
    width:50px;
  }
}

.shadow{
  background:url('/images/pattern.png') repeat 50%/10px;
  height:100%;
  position: absolute;
  top:0;
  bottom:0;
}