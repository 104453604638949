@import 'basics';

.wrapper{
  margin:10px 0 5px;
}
.root{
  width:100%;
  height:48px;
  margin:0 0 5px;
  border:1px solid rgba($white, 0.15);
  background:$bg-darkest;
  color:$white;
  padding:0 10px;
  font-size:13px;
  border-radius: 4px;
  transition: border-color ease-in-out 300ms, box-shadow ease-in-out 400ms;

  &:hover{
    border-color:$primary;
  }

  &:focus{
  border-color:$primary;
    box-shadow: 0 0 0 3px rgba($primary, 0.5);
  }
}

.error{
  color:$danger;
  font-size:12px;
}