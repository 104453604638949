@import 'basics';

.select{
  width:100%;
}

.buttons{
  margin-top:20px;
  display: flex;
  justify-content: flex-end;
}