@import 'basics';

.root{
  padding: 7px 10px;
  border:1px solid rgba($white, 0.15);
  border-radius: 4px;
  box-shadow: 0 3px 12px 0 rgba($black, 0.4);
  text-align: left;
  margin: 10px 0;
}

.default{
  background:$bg-dark;
  border-color:$border-dark;
}

.danger{
  background:$danger;
  border-color: $danger-bright;
}