.timeline{
  margin-bottom:15px;
  height:15px;
  display: flex;
}

.timelineName{
  position: relative;
  
  &::before{
    display: block;
    content: attr(data-insight);
    height:15px;
    font-size:11px;
    line-height:15px;
    position: absolute;
    top:0;
    right:6px;
    white-space: nowrap;
  }
}

.active{
  border-radius: 4px;
}