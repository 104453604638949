@import 'basics';

.noTasks{
  display: flex;
  flex-direction: column;
  flex:1;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
}

.noTasksImage{
  width:500px;
  object-fit: contain;
  margin-bottom: 20px;
}

.header{
  margin-bottom: 16px;
}

.content{
  opacity: 0.75;
  font-size:14px;
  font-weight: 200;
  margin-bottom: 40px;
}

.cta{
  height:45px;
  padding:0 100px;
}



.tableRoot{
  overflow: hidden;
  border-radius: 6px;
  border:1px solid $border-dark;
  background:$bg-dark-lighter;
  box-shadow: 0 3px 12px 0 rgba($black, 0.4);
  width:100%;
  margin: 20px 0;
}

.table{
  width: 100%;
  text-align: left;

  tr{
    font-size: 12px; 
    height:52px;
    font-weight: normal;
    vertical-align: middle;
    display: table-row;
  }
  
  th, td{
    vertical-align: middle;
    padding:0 15px;
  }
  
  tbody{
    tr{
      background:transparent;
      transition: background-color 300ms ease-in-out;
      border-top:1px solid $border-dark;
      
      &:hover{
        background:rgba(255, 255, 255, 0.05);
      }
    }
  }
}