@import 'basics';

.wrapper{
  position: fixed;
  top:0;
  left:0;
  z-index: 2;
  background:rgba($black,0.5);
  height:100vh;
  width:100vw;
  display: flex;
}

.box{
  display: flex;
  flex-direction: column;
  background:$bg-darker;
  border-radius: 10px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
  margin:auto;
  max-width:75vw;
  min-width: 450px;
  max-height:75vh;
  overflow-y: auto;
}

.header{
  min-height:60px;
  border-bottom:1px solid $bg;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.6);
  background:$bg-darker;
  display: flex;
  padding:15px;
  position: sticky;
  top:0;
  z-index:99;
}

.title{
  margin:0 !important;
  padding-right:20px;
}

.close{
  height:24px;
  width:24px;
  margin-left:auto;
  cursor: pointer;
  transition: 300ms opacity ease-in-out, transform 200ms ease-in-out;
  opacity: 0.5;
  
  &:hover{
    opacity: 1;
  }

  &:active{
    transform: scale(0.9);
  }
}

.body{
  padding: 15px;
}