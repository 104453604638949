@import 'basics';

.loader{
  margin-top:5px;
  margin-left:15px;
}

.executed{
  display: inline-block;
  margin-left:7px;
}

.error{
  color:$danger;
}