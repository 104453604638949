@import 'basics';

.page{
  display: flex;
  height:100vh;
  width:100vw;
  align-items: center;
  justify-content:center;
  flex-direction: column;
}

.logo{
  height:30px;
}

.root{
  border-radius: 4px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
  background: $bg-dark-light;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media #{$desktop}, #{$tablet}{
    padding:50px;
    width:524px;
    text-align: center;
    display: flex;
  }

  @media #{$mobile}{
    padding:15px;
    max-width:324px;
    text-align: center;
    display: flex;
  }

  h2{
    @media #{$mobile}{
      margin-top:10px;
    }
  }
}

.ob {
  width:150px;
  margin:10px 0;

  @media #{$mobile}{
    display: none;
  }
}