@import 'basics';

.wrapper{
  margin:10px 0 10px;
}

.root{
  margin:0;
  width:100%;
}

.error{
  color:$danger;
  font-size:12px;
  margin-top:-5px;
  display: block;
}